.logoContainer {
  display: flex;
  align-items: center;

  .actionButton {
    margin: 15px;
  }

  .logo {
    display: flex;
    margin: 0px 10px;
    align-items: center;

    .detalks {
      display: flex;

      img {
        height: 55px;
        width: auto;
      }
    }
  }
}
